<template>
  <div class="scroll-horizontal-slider-fallback-slide" ref="fallbackSlide">
    <NuxtLink
      :to="localePath(props.content.route)"
      :data-hoverable="$t('cursor.discover')"
    >
      <div class="scroll-horizontal-slider-fallback-slide__image">
        <WidgetPicture
          v-if="props.content.cover"
          :image="{
            custom: props.content.cover,
          }"
          :lazyLoad="true"
          :alt="props.content.cover?.title"
        />
      </div>
      <!-- Testo Negativo limitato a wrap immagine -->
      <div class="scroll-horizontal-slider-fallback-slide__text">
        <div class="title-l">
          <h2 data-animate="fallback-slide-text">{{ props.content.title }}</h2>
        </div>
      </div>
    </NuxtLink>
  </div>
</template>

<script>
export default {
  name: 'WidgetScrollHorizontalSliderFallbackSlide',
}
</script>

<script setup>
import { useSwiperSlide } from 'swiper/vue'
import { gsap } from 'gsap'

const props = defineProps({
  content: Object,
})

const swiperSlide = useSwiperSlide()

const fallbackSlide = ref(null)

watch(
  () => swiperSlide.value,
  (swiperSlide) => {
    const isActive = swiperSlide.isActive
    const text = fallbackSlide.value?.querySelector(
      '[data-animate="fallback-slide-text"]'
    )

    if (isActive) {
      gsap.to(text, {
        y: '0%',
        opacity: 1,
      })
    } else {
      gsap.to(text, {
        y: '101%',
        opacity: 0,
      })
    }
  }
)
</script>

<style lang="scss">
@import './style.scss';
</style>
