<template>
  <div class="scroll-horizontal-section-block" ref="block">
    <NuxtLink
      :to="localePath(props.content.route)"
      :data-hoverable="$t('cursor.discover')"
    >
      <div
        data-animate="block-image"
        class="scroll-horizontal-section-block__image"
      >
        <WidgetPicture
          v-if="props.content.cover"
          :image="{
            custom: props.content.cover,
          }"
          :lazyLoad="props.index < 3 ? false : true"
          :alt="props.content.cover?.title"
        />
      </div>
      <!-- Testo Negativo limitato a wrap immagine -->
      <div class="scroll-horizontal-section-block__text">
        <div class="title-l">
          <h2 data-animate="block-text">{{ props.content.title }}</h2>
        </div>
      </div>
    </NuxtLink>
  </div>
</template>

<script>
export default {
  name: 'WidgetScrollHorizontalSectionBlock',
}
</script>

<script setup>
import { gsap } from 'gsap'

const props = defineProps({
  show: Boolean,
  content: Object,
  index: Number,
})

const block = ref(null)

const tl = ref(null)

watch(
  () => props.show,
  (show) => {
    if (!tl.value?.isActive()) {
      tl.value = show ? animateIn() : animateOut()
      tl.value.play()
    }
  }
)

// Animazioni
const animateIn = (params) => {
  const parent = block.value
  const text = parent.querySelectorAll('[data-animate="block-text"]')

  const tl = gsap.timeline({
    paused: true,
    onComplete: () => {
      tl.kill()
    },
    ...params,
  })
  tl.set(parent, { autoAlpha: 1 })
  tl.to(text, {
    y: '0%',
    opacity: 1,
  })

  return tl
}

const animateOut = (params) => {
  const parent = block.value
  const text = parent.querySelectorAll('[data-animate="block-text"]')

  const tl = gsap.timeline({
    paused: true,
    onComplete: () => {
      tl.kill()
      gsap.set([parent, text], {
        clearProps: 'all',
      })
    },
    ...params,
  })
  tl.to(text, {
    y: '101%',
    opacity: 0,
  })
  tl.set(parent, { autoAlpha: 0 })

  return tl
}

defineExpose({
  animateIn,
  animateOut,
})
</script>

<style lang="scss">
@import './style.scss';
</style>
