<template>
  <div class="scroll-horizontal-section" ref="horizontalScroll">
    <WidgetScrollHorizontal>
      <div
        class="scroll-horizontal-section__item"
        v-for="(item, index) in props.items"
        :index="index"
      >
        <WidgetScrollHorizontalSectionBlock
          ref="blocks"
          :index="index"
          :content="item"
        />
      </div>
    </WidgetScrollHorizontal>
  </div>
</template>

<script>
export default {
  name: 'WidgetScrollHorizontalSection',
}
</script>

<script setup>
import { debounce } from '~/assets/js/utils.js'
import { gsap } from 'gsap'

const props = defineProps({
  items: Array,
})

const horizontalScroll = ref(null)

const blocks = ref([])
const activeBlocks = new Set()

onMounted(() => {
  window.addEventListener('resize', debounce(onResize))
  onResize()

  window.addEventListener('scroll', onScroll)
  onScroll()
})

onUnmounted(() => {
  window.removeEventListener('resize', debounce(onResize), false)
  window.removeEventListener('scroll', onScroll, false)
})

// Animo i titoli dei blocchi allo scroll
const onScroll = () => {
  const parent = horizontalScroll.value

  const slides = gsap.utils.toArray(
    parent?.querySelectorAll('.scroll-horizontal-section__item')
  )

  // Get the 25% and 75% points of the window width

  if (slides.length > 1) {
    const computedStyle = window.getComputedStyle(slides[1])
    const marginLeft = parseFloat(computedStyle.marginLeft)

    slides.forEach((slide, index) => {
      const rect = slide.getBoundingClientRect()

      const conditionToAnimate =
        index === 0
          ? rect.x <= window.innerWidth &&
            rect.x >= window.innerWidth * 0.5 - rect.width - marginLeft
          : rect.x <= window.innerWidth * 0.5 &&
            rect.x >= window.innerWidth * 0.5 - rect.width - marginLeft

      if (conditionToAnimate) {
        if (!activeBlocks.has(slide)) {
          // Add 'active' class to the element
          slide.classList.add('--active')

          // Add element to the set of active elements
          activeBlocks.add(slide)
          blocks.value?.[index]?.animateIn().play()
        }
      } else {
        if (activeBlocks.has(slide)) {
          // Remove 'active' class from the element
          slide.classList.remove('--active')

          // Remove element from the set of active elements
          activeBlocks.delete(slide)
          blocks.value?.[index]?.animateOut().play()
        }
      }
    })
  }
}

const onResize = () => {
  const parent = horizontalScroll.value
  const wrap = parent?.querySelector('.scroll-horizontal__wrap')
  const children = gsap.utils.toArray(
    parent?.querySelectorAll('.scroll-horizontal-section__item')
  )

  if (children.length) {
    const firstItem = children[0]
    const lastItem = children[children.length - 1]
    // Imposto il margine del primo elemento (100% sinistro)
    gsap.set(firstItem, {
      marginLeft: window.innerWidth, // - lastItem.offsetWidth / 2 Rimettere se voglio che l'elemento iniziale sia a metà dello schermo,
    })
    // Imposto il margine dell'ultimo elemento elemento (50% destro)
    gsap.set(lastItem, {
      marginRight: window.innerWidth / 2, // - lastItem.offsetWidth / 2,
    })
    gsap.set(wrap, { top: window.innerHeight / 2 - lastItem.offsetHeight / 2 })
  }
}
</script>

<style lang="scss">
@import './style.scss';
</style>
