<template>
  <swiper
    class="scroll-horizontal-slider-fallback"
    :modules="[Pagination, A11y]"
    :slides-per-view="props.options?.slidesPerView || 'auto'"
    :centered-slides="true"
    :pagination="{ clickable: false }"
    :lazy="true"
  >
    <swiper-slide v-for="(slide, index) in props.slides" :key="index">
      <div class="scroll-horizontal-slider-fallback__slide__wrap">
        <WidgetScrollHorizontalSliderFallbackSlide :content="slide" />
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
export default {
  name: 'WidgetScrollHorizontalSliderFallback',
}
</script>

<script setup>
import { Pagination, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'

const props = defineProps({
  slides: Array,
  options: {
    type: Object,
  },
})
</script>

<style lang="scss">
@import './style.scss';
</style>
