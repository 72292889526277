<template>
  <div class="scroll-horizontal" ref="scrollHorizontal">
    <div class="scroll-horizontal__wrap">
      <div class="scroll-horizontal__content" ref="scrollHorizontalContent">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WidgetScrollHorizonal',
}
</script>

<script setup>
import { debounce } from '~/assets/js/utils.js'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const scrollHorizontal = ref(null)
const scrollHorizontalContent = ref(null)

const windowSizes = ref({ height: 0, width: 0 })

onMounted(() => {
  window.addEventListener('resize', debounce(getWindowSize))
  getWindowSize()

  window.addEventListener('scroll', onScroll)
  onScroll()
})

onUnmounted(() => {
  window.removeEventListener('resize', debounce(getWindowSize), false)
  window.removeEventListener('scroll', onScroll, false)
})

watch(
  () => windowSizes.value,
  () => {
    const scrollHorizontalContentRect =
      scrollHorizontalContent.value.getBoundingClientRect()
    gsap.set(scrollHorizontal.value, {
      height: `${scrollHorizontalContentRect.width}px`,
    })
    ScrollTrigger.refresh()
  }
)

const onScroll = () => {
  // Controlla se i riferimenti agli elementi scrollHorizontal o scrollHorizontalContent sono nulli e, in tal caso, esce dalla funzione
  if (!scrollHorizontal.value || !scrollHorizontalContent.value) return

  const containerTopToViewportTop =
    scrollHorizontal.value.getBoundingClientRect().top

  const viewportHeight = window.innerHeight

  const containerHeight = scrollHorizontal.value.offsetHeight

  // Definisce il punto di inizio dell'animazione come la posizione in cui il contenitore è appena sopra la viewport
  const start = 0 - containerHeight

  // Definisce il punto di fine dell'animazione come il momento in cui il contenitore è completamente entrato nella viewport
  const end = viewportHeight

  // Calcola il progresso dell'animazione come un valore fra 0 e 1, basandosi sulla posizione attuale del contenitore rispetto alla viewport
  let progress = mapRange(start, end, containerTopToViewportTop, 0, 1)

  // Assicura che il valore di progresso sia sempre compreso tra 0 e 1
  progress = clamp(0, progress, 1)

  // Calcola la larghezza del contenuto all'interno di scroll-horizontal__content
  const scrollHorizontalContentWidth = scrollHorizontalContent.value.scrollWidth

  // Determina la massima traslazione orizzontale possibile basata sulla larghezza del contenuto meno la larghezza della viewport
  const maxScrollX = scrollHorizontalContentWidth - windowSizes.value.width

  // Calcola la traslazione orizzontale attuale (x) per far muovere il contenuto da destra verso sinistra, basandosi sul progresso
  const x = maxScrollX - progress * maxScrollX

  // Applica la traslazione calcolata a tutti gli elementi figli di scroll-horizontal__content, spostandoli orizzontalmente
  gsap.set([...scrollHorizontalContent.value.children], {
    x: -x, // Il segno negativo assicura che la direzione del movimento sia da destra verso sinistra
  })
}

const getWindowSize = () => {
  windowSizes.value = {
    width: window.innerWidth,
    height: window.innerHeight,
  }
}

const mapRange = (in_min, in_max, input, out_min, out_max) =>
  ((input - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
const clamp = (min, input, max) => Math.max(min, Math.min(input, max))
</script>

<style lang="scss">
@import './style.scss';
</style>
