<template>
  <section class="page-home-hihglight-products p-top-3 p-bot-3">
    <!-- Title -->
    <div class="row-1">
      <h2
        class="title-m"
        v-anim-reveal-text
        v-html="props.content.homeHighlightsContentTitle"
      />
      <div
        class="page-home-hihglight-products__text text-l m-top-16px"
        v-html="props.content.homeHighlightsContentText"
      />
    </div>
    <div class="m-top-3 row-1 flex --column --align-center">
      <h2 class="text-l text-uppercase">
        {{ $t('pages.home.highlightProductTitle') }} {{}}
      </h2>
    </div>
    <!-- Horizontal Scroll -->
    <div
      v-if="!hideHorizontalScroll && rendered"
      class="m-top-4 page-home-hihglight-products__horizontal"
    >
      <WidgetScrollHorizontalSection :items="highlights" />
    </div>

    <div
      v-else-if="rendered"
      class="m-top-4 page-home-hihglight-products__swiper"
    >
      <WidgetScrollHorizontalSliderFallback :slides="highlights" />
    </div>

    <div class="m-top-5 row-1 flex --column --align-right">
      <WidgetLinkMainUnderline
        :light="true"
        :link="{
          key: 'all-collections',
          route: { name: 'typology-collections' },
        }"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: 'PagesHomeHighlightProducts',
}
</script>

<script setup>
import { debounce } from '~/assets/js/utils.js'

import { useCraftStore } from '@/store/craft.js'

const props = defineProps({
  content: Object,
})

const { configuration } = useCraftStore()

const viewport = ref(0)
const touch = ref(false)

const rendered = ref(false)

const highlights = computed(() => {
  const market = configuration.markets.find(
    (market) => market.id === configuration.marketId
  )?.key
  const products =
    market === 'en-us'
      ? props.content.homeHighlightsContentProductUsa
      : props.content.homeHighlightsContentProductGlobal

  return products.map((product) => ({
    id: product.id,
    title: product.title,
    cover: product.cover?.[0],
    route: {
      name: 'product-family-slug',
      params: { slug: product.slug },
    },
  }))
})

const hideHorizontalScroll = computed(() => {
  return viewport.value < 749 && touch.value > 0
})

onMounted(() => {
  touch.value = 'ontouchstart' in window || navigator.maxTouchPoints
  updateViewportSize()
  rendered.value = true
  window.addEventListener('resize', debounce(updateViewportSize))
})

onUnmounted(() => {
  window.removeEventListener('resize', debounce(updateViewportSize))
})

const updateViewportSize = () => {
  viewport.value = window.innerWidth
}
</script>

<style lang="scss">
@import './style.scss';
</style>
